export default {
  // Endpoints
  // loginEndpoint: 'https://apitest.niso.dev/pamis/identity/v1/Login',
  //  loginEndpoint:  'https://api.eldor.com.tr/pamis/identity/v1/Login',

  loginEndpoint:
    process.env.NODE_ENV === "production"
      ? `${process.env.VUE_APP_API_URL}/pamis/identity/v1/Login`
      : "https://apitestlocal.niso.dev/pamis/identity/v1/Login",

  moduleEndpoint: "/admin/clientmodules",
  logincompanyEndpoint: "/admin/logincompany",
  registerEndpoint: "/jwt/register",
  refreshEndpoint: "",
  //  refreshEndpoint: 'https://api.eldor.com.tr/pamis/identity/v1/GetAccessTokenWithRefreshToken',
  /* refreshEndpoint:
     process.env.NODE_ENV === "production"
       ? `${process.env.VUE_APP_API_URL}/pamis/identity/v1/GetAccessTokenWithRefreshToken`
       : "https://apitest.niso.dev/pamis/identity/v1/GetAccessTokenWithRefreshToken",
   // refreshEndpoint: 'https://apitest.niso.dev/pamis/identity/v1/GetAccessTokenWithRefreshToken',
   */
  logoutEndpoint: "/jwt/logout",
  getUsers: "/admin/f/users",
  getUsersByRole: "/admin/f/usersbyrole",
  addUser: "/admin/createuser",
  getCategories: "/lms/admin/course/category",
  addCategory: "/lms/admin/course/category",
  getCourses: "/lms/admin/course/getallcourses",
  getCourse: "/lms/admin/course/getcoursedetail",
  addCourse: "/lms/admin/course/create",
  updateCourse: "/lms/admin/course/updateCourse",

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageClientAuthTokenKeyName: "clientAuthToken",
  storageClientLogoKeyName: "clientLogo",

  storageRefreshTokenKeyName: "refreshToken",
};
