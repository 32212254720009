import Oidc from 'oidc-client'

Oidc.Log.logger = console
Oidc.Log.level = (process.env.NODE_ENV === 'production') ? Oidc.Log.ERROR : Oidc.Log.DEBUG

// OIDC configuration
//const oidcProviderDomain = 'https://localhost:5240';
const oidcProviderDomain = 'https://apitestlocal.niso.dev/pamis/identity-v2'
const clientId = (process.env.NODE_ENV === 'production') ? 'vueclient' : 'vueclient_dev'
const scopes = 'openid profile email offline_access'

let instance

// OIDC Client
// eslint-disable-next-line 
export const getOidcClient = () => {
  if (instance) {
    return instance
  }

  instance = new Oidc.UserManager({
    userStore: new Oidc.WebStorageStateStore(),
    authority: oidcProviderDomain,
    client_id: clientId,
    redirect_uri: `${window.location.origin}/callback`,
    post_logout_redirect_uri: `${window.location.origin}/home?action=logout`,
    response_type: 'code',
    scope: scopes,
    accessTokenExpiringNotificationTime: 10,
    automaticSilentRenew: false,
    filterProtocolClaims: false,
    loadUserInfo: true,
    includeIdTokenInSilentRenew: false,
  })
  return instance
}
